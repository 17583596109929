exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leistung-preise-js": () => import("./../../../src/pages/leistung-preise.js" /* webpackChunkName: "component---src-pages-leistung-preise-js" */),
  "component---src-pages-patienteninfo-js": () => import("./../../../src/pages/patienteninfo.js" /* webpackChunkName: "component---src-pages-patienteninfo-js" */),
  "component---src-pages-uber-mich-js": () => import("./../../../src/pages/über-mich.js" /* webpackChunkName: "component---src-pages-uber-mich-js" */)
}

